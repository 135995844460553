import React from "react";
import { Spin as AntdSpin } from 'antd';
import Loadable from 'react-loadable';
import "./index.less";
import Config from "../config"
class Spin extends React.Component {
  render() {
    let props = {...this.props};
    delete props.isLoading;
    delete props.pastDelay;
    delete props.error;
    delete props.timedOut;
    delete props.retry;
    delete props.computedMatch;
    return (
      <AntdSpin className="loadingSpin" {...props} />
    );
  }
}



export const BuildForm = Loadable({
  loader: () => import('../views/buildform/build'),
  loading: Spin,
});

export const Login = Loadable({
  loader: () => import('../views/login'),
  loading: Spin,
});
export const NewLogin = Loadable({
  loader: () => import('../views/newlogin'),
  loading: Spin,
});
export const Resetpwd = Loadable({
  loader: () => import('../views/resetpwd'),
  loading: Spin,
});
export const Home = Loadable({
  loader: () => import('../views/home'),
  loading: Spin,
});

export const GdHome = Loadable({
  loader: () => import('../views/gongdhome'),
  loading: Spin,
});
export const Points = Loadable({
  loader: () => import('../views/points'),
  loading: Spin,
});
export const Design = Loadable({
  loader: () => import('../views/design'),
  loading: Spin,
});
export const Geolog = Loadable({
  loader: () => import('../views/geolog'),
  loading: Spin,
});
export const GeoPhysicalDetail = Loadable({
  loader: () => import('../views/geophysicaldetail'),
  loading: Spin,
});
export const GeologoneDetail = Loadable({
  loader: () => import('../views/geologonedetail'),
  loading: Spin,
});
export const GeologtwoDetail = Loadable({
  loader: () => import('../views/geologtwodetail'),
  loading: Spin,
});
export const GeologthreeDetail = Loadable({
  loader: () => import('../views/geologthreedetail'),
  loading: Spin,
});
export const GeologfourDetail = Loadable({
  loader: () => import('../views/geologfourdetail'),
  loading: Spin,
});
export const GeologfiveDetail = Loadable({
  loader: () => import('../views/geologfivedetail'),
  loading: Spin,
});

export const DesignCmrn = Loadable({
  loader: () => import('../views/designcmrn'),
  loading: Spin,
});

export const Daily = Loadable({
  loader: () => import('../views/daily'),
  loading: Spin,
});
export const JsonForm = Loadable({
  loader: () => import('../views/jsonform'),
  loading: Spin,
});

export const Layout = Loadable({
  loader: () => {
    const ret = import('../views/layout');
    return ret },
  loading: Spin,
});

export const Profile = Loadable({
  loader: () => import('../views/profile'),
  loading: Spin,
});
export const ManagerFzf = Loadable({
  loader: () => import('../views/fzf'),
  loading: Spin,
});
export const ManagerFormList = Loadable({
  loader: () => import('../views/manager/formlist'),
  loading: Spin,
});
export const Threshold = Loadable({
  loader: () => import('../views/manager/threshold'),
  loading: Spin,
});
export const Dictionary = Loadable({
  loader: () => import('../views/manager/dictionary'),
  loading: Spin,
});
export const fromtest = Loadable({
  loader: () => import('../views/manager/fromtest'),
  loading: Spin,
});
export const fromtests = Loadable({
  loader: () => import('../views/manager/fromtests'),
  loading: Spin,
});

export const Popedoms = Loadable({
  loader: () => import('../views/manager/popedoms'),
  loading: Spin,
});

export const ManagerRoleList = Loadable({
  loader: () => import('../views/manager/rolelist'),
  loading: Spin,
});
export const SyncInfor = Loadable({
  loader: () => import('../views/manager/syncinfor'),
  loading: Spin
});
export const SyncHronous = Loadable({
  loader: () => import('../views/manager/synchronous'),
  loading: Spin
});
export const SiteInfor = Loadable({
  loader: () => import('../views/manager/siteinfor'),
  loading: Spin
});
//新版本写法
// class FatalError extends React.Component {
//   render() {
//     return (<div>组件加载错误</div>
//     );
//   }
// }
export const BuildFormV2 = Loadable({
  loader: () => import('../views/build/build'),
  loading: Spin
  // () => import('../views/build/build'),{
  // LoadingComponent: Spin, // what to display during the loading
  // ErrorComponent: FatalError // what to display in case of error
});
