import axios from "axios";
import Config from "../config";
// import { useStore } from "../store/configureStore";
import { useCommonStore } from "../store/useCommonStore";

/**
 * 构建 Form 数据请求
 */
function prepareForForm(data) {
    let newBody = '';
    for (let k in data) {
        let v = data[k];
        newBody += `${k}=${v}&`;
    }
    return newBody;
}

/**
* 检查请求回应数据包，判断如果是未认证，跳转到登录地址
*
* @param  {object} response   请求包对象
*
* @return {object|undefined} 返回响应包或者抛出异常
*/
function checkStatus(response) {
    
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    if (response.status == 400) {
        return response;
    }
    if (response.status == 401) {
        localStorage.removeItem("user");
        window.location.href = Config.RoutePrefix + '/login';
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
}


//const auth = useStore(s=>s.common.user?.auth);
const dealOption = (method, contentType, data,timeout) => {
    let headers;
    let body;
    
    //const auth = useStore.getState()?.common?.user?.auth;
     let auth = useCommonStore.getState()?.user?.auth;
    // if(auth==null){
    //     try{
    //         auth=JSON.parse(localStorage.user).auth
    //     }catch(e){
    //         console.log("认证解析失败");
    //     }
        
    // }
    // const  auth = useCommonStore.use.user()?.auth;
    if (auth) headers = { ...headers, "Authorization": auth.name, "wsctoken": auth.token };
    //headers = {...headers,mode: 'no-cors'};
    //if (appid) headers = { ...headers, "dapr-app-id": appid }
    
    switch (contentType) {
        case "form":
            headers = { ...headers, "Content-Type": 'application/x-www-form-urlencoded' };
            //headers.Content-Type = 'application/x-www-form-urlencoded';
            body = prepareForForm(data);
            break;
        case "json":
            headers = { ...headers, "Content-Type": 'application/json' };
            //headers.Content-Type = 'application/json';
            body = data;
            break;
        default:
            headers = { ...headers, "Content-Type": 'application/json' };
            //headers.Content-Type = 'application/json';
            body = JSON.stringify(data)
            break;
    }
    return {
        method: method,
        data: body,
        headers: headers,
        timeout:timeout,
        validateStatus: function (status) {
            return status >= 200 && status < 600; // default
          },
        //credentials: 'same-origin'
    };
}

//export default UseRequest;
export const request = (url, method, contentType, data,timeout) => {
    const options = dealOption(method, contentType, data,timeout);
    const turls = (url?.indexOf("http") > -1) ? url : Config.ApiHost + url;
    
    return axios(turls, options)
        .then(checkStatus, (reson) => {
       
            let b = 0;
        })
        .then(resp => {
            return resp.data;
        }
        ).catch((reson)=>{
            let i = 0;
          });
}

export default () => { return { request } };

