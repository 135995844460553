import React, { useEffect } from 'react';
import { ConfigProvider, Spin } from 'antd';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import zhCN from 'antd/es/locale-provider/zh_CN';
import Config from "../config";
import { CustomFormProvider } from '../component/CustomFormProvider';
import * as Pages from "../route";
import { useCommonStore } from "../store/useCommonStore";



const InitLoading = ({ height, width }) => {
	return (<Spin delay={200} className="mt-8" tip="初始化基础数据中..."><div style={{ height: height, width: width }}></div></Spin>);
}

const Root = () => {

	let authData = null;

	const inited = useCommonStore.use.inited();
	const initdata = useCommonStore.use.initdata();
	debugger
	useEffect(() => {
		if (localStorage.user != undefined && localStorage.user != null) {
			if (localStorage.user == "null") {
				localStorage.removeItem("user");
				window.location.href = '/login';
			}
			authData = JSON.parse(localStorage.user);
		}  else if (window.location.pathname.indexOf("/login") == -1 && window.location.pathname.indexOf("/newlogin") == -1) {
			//无用户信息，跳转到登陆页面

			window.location.href = '/login';
		}
		console.log(authData);
		if (authData != undefined && authData != {} && authData != null) {
			if (inited) return;
			// 初始化
			initdata(authData)
		}
	}, []);


	return (
		<CustomFormProvider>
			<ConfigProvider locale={zhCN}>
				<Router>
					<Switch>
						<Route path={Config.RoutePrefix + "/login"} component={Pages.Login}></Route>
						<Route path={Config.RoutePrefix + "/newlogin"} component={Pages.NewLogin}></Route>
						<Route path={Config.RoutePrefix + "/jsonform"} component={Pages.JsonForm}></Route>
						<Route path={Config.RoutePrefix + "/build:/id"} component={Pages.BuildForm}></Route>
						<Route path={Config.RoutePrefix + "/notfound"} component={Pages.ManagerFzf}></Route>
						<Route path={Config.RoutePrefix + "/buildv2/:id"} component={Pages.BuildFormV2}></Route>
						{inited ?
							<PrivateRoute path={Config.RoutePrefix + "/"} component={Pages.Layout}>
							</PrivateRoute>
							: <InitLoading />}
						{/* {inited ? null : <InitLoading  />} */}
					</Switch>
				</Router>
			</ConfigProvider>
		</CustomFormProvider>
	);
}



const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={props => {
		if (localStorage.user != 'null' && localStorage.user != undefined) {
			
			return (<Component {...props} />);
		} else {
			
			return (
				<Redirect to={{
					pathname: '/login',
					state: { from: props.location }
				}} />);
		}
	}} />
)
export default Root;