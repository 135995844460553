
import {
    createSelectors,
    createStoreWithStorageNameAndBlacklist,
} from "./base";
import { request } from "../utils/useRequest";
import _ from "lodash";
import Config from '../config'

const initState = {
    inited: false,
    area: [],
    areatree: null,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    initialitem: { userid: "", initialid: "" },
    loginError: '',
    structure: [],
    rolelist: [],
    sysdicts: [
        {
            id: "1",
            Name: "Kwwz",
            Desc: "孔位位置",
            Data: [
                {
                    code: "1",
                    name: "左边墙"
                },
                {
                    code: "2",
                    name: "右边墙"
                },
            ]
        }
    ],
    menus: [],
    commenu: new Object(),
    selectkey: '',
    initialpanelkey: '',
    panelist: new Array(),
    speed: null,
    methodlist: [],
    user: null,
    topmenu_id: 'gdhome',
    designkey: '1',
    geologkey: '1',
    parentitem: new Object(),
    gditem: { children: [] },
    keyitem: new Object(),
    keydata: new Object(),
    leaditem: new Object(),
    edititem: new Object(),
    sbmititem: new Object(),
    edititemdetail: new Object(),
    onecolor: "#fff",
    twocolor: "#fff",
    threecolor: "#fff",
    fourcolor: "#fff",
    fivecolor: "#fff",
    subdetail: new Object(),
    roleuserlist: [],
    upmeth:"",
    fxlbdata:[],
    rockorsoil:"1",
    collectiontype:"1",
    crmntype:"1",
    upuserid:""
};

// const createStore = createStoreWithName('jymt-common-storage');
const createStore = createStoreWithStorageNameAndBlacklist('cqdzyb-common-storage', ['inited']);

export const commonBaseStore =
    createStore(
        (set, get) => ({
            ...initState,
            initdata: async () => {
                set(state => {
                    state.inited = true;
                });
            },
            login: async (uid, pwd, remember, code) => {
                //const { request } = UseRequest();
                let item = { uid: uid, pwd: pwd, code: code, }
                const requestUrl = Config.ApiHost + "/api/login/Login"
                const result = await request(requestUrl, "POST", "json", item);
                if (result.success == true) {
                    localStorage.user = JSON.stringify(result.data);
                    if (remember === true) {
                        window.localStorage._users = uid;
                        window.localStorage._pews = pwd;
                    } else if (remember === false) {
                        window.localStorage._users = "";
                        window.localStorage._pews = "";
                    }
                    set(state => {
                        state.user = { ...result.data };
                        state.inited = true
                    });
                }
                return result;
            },
            newlogin: async (uid, pwd,) => {
                //const { request } = UseRequest();
                debugger
                let item = { uid: uid, pwd: pwd }
                const requestUrl = Config.ApiHost + "/api/login/sigLogin"
                const result = await request(requestUrl, "POST", "json", item);
                if (result.success == true) {
                    localStorage.user = JSON.stringify(result.data);
                    window.localStorage._users = uid;
                    window.localStorage._pews = pwd;
                    set(state => {
                        state.user = { ...result.data };
                        state.inited = true
                    });
                }
                return result;
            },
            logout: () => {
                set(state => {
                    state.user = null;
                    localStorage.user=null
                });
                return true;
            },
            initdatas: async () => {
                
                const initParams = {
                    pagination: {
                      current: 1,
                      pageSize: 10
                    },
                    filters: null,
                    sorter: null,
                  };
                const result = await request("/api/fgpperson/getCustomerRoleList", "POST", "json",initParams);
                if (result.success) {
                    let item = {
                        id: "2",
                        Name: "Roil",
                        Desc: "角色列表",
                        Data: result.data,
                    }
                    let list = [{
                        id: "1",
                        Name: "Kwwz",
                        Desc: "孔位位置",
                        Data: [
                            {
                                code: "1",
                                name: "左边墙"
                            },
                            {
                                code: "2",
                                name: "右边墙"
                            },
                        ]
                    },]
                    list.push(item)
                    
                    set(state => {
                        state.sysdicts = list;

                    });
                }

                return
            },
            ChangePassword: async (item) => {
                const result = await request("/api/fgpperson/resetPassword", "POST", "json", item);
                return result
            },
            ChangePwd: async (item) => {
                const result = await request("/api/fgpperson/userPasswordRest", "POST", "json", item);
                return result

            },
            changePwdRest: async (item) => {
                const result = await request("/api/fgpperson/resetPassword", "POST", "json", item);
                return result
            },
            getRoleLists: async (item) => {
                const result = await request("/api/fgpperson/getCustomerRoleList", "POST", "json", item);
                if (result.success) {
                    set(state => {
                        state.roleuserlist = result.data;
                    });
                }
                return;
            },
            getMenu: async () => {
                const result = await request("/api/segment/getSegmentList", "POST", "json", new Object());
                if (result.success) {
                    set(state => {
                        state.menus = result.data;
                    });
                }
                return;
            },

            getFxlblist: async () => {
                const result = await request("/api/v2/private/dictionary/getByCodeVaule", "POST", "json",  {
                    code: "fdxxfxlb"
                  });
                if (result.success) {
                    set(state => {
                        state.fxlbdata = result.data
                    });
                }
            },
            getStructure: async (id) => {

                let item = { Scf_SegmentID: id }
                const result = await request("/api/strcture/getStructure", "POST", "json", item);
                if (result.success) {
                    set(state => {
                        state.structure = result.data
                    });
                }
                return result;
            },
            setStructure: (list) => {
                set(state => {
                    state.structure = list;
                });
            },
            getMethodList: async () => {
                const result = await request("/api/v2/private/dictionary/getByCodeVaule", "POST", "json", {
                    code: "project"
                });
                if (result.success) {
                    set(state => {
                        state.methodlist = result.data;
                    });
                }
                return;
            },
            getGroupCommonInfo: async (id) => {
                const result = await request("/api/commoninfo/getGroupCommonInfo", "POST", "json", { scfSitebasicid: id });
                if (result.success) {
                    set(state => {
                        state.panelist = result.data;
                    });
                }
                return;
            },
            getSpeed: async (id) => {
                let item = { scfSitebasicid: id }
                const result = await request("/api/commoninfo/getSchedule", "POST", "json", item);
                if (result.success) {
                    set(state => {
                        state.speed = result.current;
                    });
                }
                return result;
            },
            setInitialitem: (item) => {
                set(state => {
                    state.initialitem = item;
                });
            },
            setComMenu: (items) => {
                set(state => {
                    state.commenu = items;
                });
            },
            setSelectkey: (keyid) => {
                set(state => {
                    state.selectkey = keyid;
                });
            },
            setUpMeth: (id) => {
                set(state => {
                    state.upmeth = id;
                });
            },
            setRockOrSoil: (id) => {
                set(state => {
                    state.rockorsoil = id;
                });
            },
            setCollectionType: (id) => {
                set(state => {
                    state.collectiontype = id;
                });
            },
            setCrmnType: (id) => {
                set(state => {
                    state.crmntype = id;
                });
            },
            setUpUserid: (id) => {
                set(state => {
                    state.upuserid = id;
                });
            },
            setInitialpanelkey: (id) => {
                set(state => {
                    state.initialpanelkey = id;
                });
            },

            setTopMenuCurrentId: (id) => {
                set(state => {
                    state.topmenu_id = id;
                });
            },
            setDesignkey: (id) => {
                set(state => {
                    state.designkey = id;
                });
            },
            setGeolognkey: (id) => {
                set(state => {
                    state.geologkey = id;
                });
            },
            setParentItem: (item) => {
                set(state => {
                    state.parentitem = item;
                });
            },
            setGdHomeItem: (item) => {
                set(state => {
                    state.gditem = item;
                });
            },
            /* 全局ID */
            setKeyItem: (item) => {
                set(state => {
                    state.keyitem = item;
                });
            },
            /* 全局lenth */
            setKeyLenth: (item) => {
                set(state => {
                    state.keydata = item;
                });
            },
            setLeaditem: (item) => {
                set(state => {
                    state.leaditem = item;
                });
            },
            /* 地质预报新增修改 */
            setEdititem: (item) => {
                set(state => {
                    state.edititem = item;
                });
            },
            /* 地质预报提交数据 */
            setSbmititem: (item) => {
                set(state => {
                    state.sbmititem = item;
                });
            },
            setEdititemdetail: (item) => {
                set(state => {
                    state.edititemdetail = item;
                });
            },
            /* 颜色改变 */
            setOnecolor: (color) => {
                set(state => {
                    state.onecolor = color;
                });
            },
            setTwocolor: (color) => {
                set(state => {
                    state.twocolor = color;
                });
            },
            setThreecolor: (color) => {
                set(state => {
                    state.threecolor = color;
                });
            },
            setFourcolor: (color) => {
                set(state => {
                    state.fourcolor = color;
                });
            },
            setFivecolor: (color) => {
                set(state => {
                    state.fivecolor = color;
                });
            },
            getTopMenuFirstPage: (id) => {
                get(state => {

                });
            },
            setSubDetail: (item) => {
                set(state => {
                    state.subdetail = item;
                });
            },
        })
    ) // create
    ;

export const useCommonStore = createSelectors(commonBaseStore);
