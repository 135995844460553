const EventEmitter = require('events'); 
const emitter = new EventEmitter();

const Global = {
	User:{}
};
//exports.ApiHost = 'https://www.gynice.com';
//exports.ApiHost = 'http://localhost:7012';
// exports.ApiHost = 'http://192.168.31.156:88';
//测试服
//exports.ApiHost = 'http://124.71.80.195:9001';
// exports.appId = "wx1c64822335d570b6";
// exports.callbackUrl = "http://seecar.wsc.tech/";

//测试服1
//exports.ApiHost = 'http://192.168.31.132:80';
// exports.appId = "wx1c64822335d570b6";
// exports.callbackUrl = "http://seecar.wsc.tech/";
// 正式服
exports.ApiHost = '';
// exports.ApiHost = 'http://api.sc.wsc.tech';
exports.appId = "wx47ae05e1da5c7abf";
exports.callbackUrl = "http://sc.wsc.tech/";

exports.QiniuUrl = "http://p19ke6ch6.bkt.clouddn.com/";
exports.QiniuUploadUrl = "https://up-z2.qbox.me";
exports.AndroidAppDownload = "http://app.mi.com/details?id=com.baidu.searchbox";
exports.iOSAppDownload="https://www.baidu.com";
exports.QiniuBucket = "cxtg";
exports.Store = null;
exports.RoutePrefix = '';

exports.Global = Global;
exports.Emitter = emitter;
