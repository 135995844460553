import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css'
import Root from './containers/root';
import {DatePicker, Slider} from 'antd';



//Error log statistic
  window.addEventListener('error', (e) => {
    // Ignore ResizeObserver error
    if (e.message === 'ResizeObserver loop limit exceeded') {
      console.log(e);
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

  });



const app = (<div className='w-full h-screen'>
     <Root >
        <div className='hidden'>
          <DatePicker/>
          <Slider/>
        </div>
     </Root>
   </div>);


const mountingPoint = document.createElement('div');
mountingPoint.className = 'react-app';
const root = createRoot(mountingPoint)
document.body.appendChild(mountingPoint);

//ReactDOM.render(app, mountingPoint);
root.render(app);