import React from "react";
import { CustomFormContext } from "./CustomFormContext";
import _ from "lodash";

export const CustomFormProvider = ({ children }) => {
    const [customforms,setCustomforms] = React.useState([]);
    const setLoading = (id,status) => {
        let index = _.findIndex(customforms, { id: id });
        if (index > -1) {
            customforms.splice(index, 1, { ...(customforms[index]), loading:status });
            setCustomforms(_.clone(customforms));
        }
    }
    const setVisible = (id,status) => {
        let index = _.findIndex(customforms, { id: id });
        if (index > -1) {
            customforms.splice(index, 1, { ...(customforms[index]), visible:status });
            setCustomforms(_.clone(customforms));
        }
    }

    const getCustomForm = (id) => {
        const target = _.find(customforms,item=>item.id == id);
        if(target == null){
            return { loading:false,visible:false };
        } else {
            return target;
        }
    }
    const putCustomform = (id, customform) => {
        //setCustomforms([...customforms,])

        let index = _.findIndex(customforms, { id: id });
        if (index < 0) {
            setCustomforms([...customforms, { id: id, ...customform }]);
        } else {
            customforms.splice(index, 1, { ...(customforms[index]), id: id, ...customform });
            setCustomforms(_.clone(customforms));
        }
    }
    // const [loading, setLoading] = React.useState(false);
    // const [schema, setSchema] = React.useState(null);
    // const [formdata, setFormdata] = React.useState({});
    // const [visible, setVisible] = React.useState(false);
    return (<CustomFormContext.Provider value={{
        setVisible,
        setLoading,
        getCustomForm,
        putCustomform
    }}>
        {children}
    </CustomFormContext.Provider>);
}