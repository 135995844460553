// Log every time state is changed
import {produce} from "immer";
import {persist, devtools } from "zustand/middleware";
import pipe from "ramda/src/pipe";
import create from "zustand";
import {shallow} from "zustand/shallow";

// logger 中间件
const log = (config) => (set, get, api) =>
    config(
        (...args) => {
            console.group('state update');
            console.log(`%c  prev state %c %O`, 'color: #666', 'color: #000', get());
            console.log(`%c  action %c %O`,'color: #45e', 'color: #000', args);
            set(...args);
            console.log(`%c  current state %c %O`,'color: #4e5', 'color: #000', get());
            console.groupEnd();
        },
        get,
        api
    );

// From https://github.com/pmndrs/zustand#middleware
const withImmer = config => (set, get, api) =>
    config((fn) => set(produce(fn)), get, api);
const withPersist = config => persist(config, { name: 'some-store' });

// 使用immer+persist+logger+devtools中间件
export const createStore = pipe(withImmer, withPersist, log, devtools, create);

// 使用immer+logger中间件
export const createStoreWithImmerAndLogger = pipe(log, withImmer, create);

const withPersistForName = name => config => persist(config, { name , getStorage: () => localStorage});
export const createStoreWithName = name => pipe(withImmer, withPersistForName(name), log, devtools, create);

// 可指定Persist的storage名称和持久化黑名单
const withPersistForNameAndBlacklist = (name,blacklist) =>
        config => persist(config,
            {
                name ,
                getStorage: () => localStorage,
                partialize: state => {
                    const result = {...state};
                    blacklist.forEach(item => delete result[item]);
                    return result;
                },
            }
        );
export const createStoreWithStorageNameAndBlacklist = (name, blacklist) =>
    pipe(withImmer, withPersistForNameAndBlacklist(name, blacklist), log, devtools, create);

// 自动生成selector
export const createSelectors = _store => {
    
    let store = _store;
    store.use = {}
    for (let k of Object.keys(store.getState())) {
        ;(store.use)[k] = () => store((s) => s[k], shallow)
    }

    return store
}